<template>
  <div class="grid">
    <loading :active="isLoadingModel" :can-cancel="true" color="#274461" :is-full-page="fullPage"></loading>
    <Toast />
    <ConfirmPopup></ConfirmPopup>
    <div class="col-12 sticky">
      <div id="custom_card" class="card sticky">
        <div class="grid justify-content-between">
          <div class="col-12 md:col-6">
            <div class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3">
              <p style="color: #6b719b; font-size: 20px; font-weight: 600">
               {{$t(name_flag)}}  {{ $t('Tickets') }}
              </p>
            </div>
          </div>
          <div class="col-12 md:col-6" v-if="user_type != 'Agent'">
            <div class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3">
              <!-- <Button iconPos="right" label="Create Ticket" icon="pi pi-plus" v-tooltip.bottom="'Create Ticket'"
                @click="openNew" size="small"
                style="box-shadow: 0 2px 6px rgba(0,176,176,.549);border-color: #00b0b0!important;background-color: #00b0b0!important;" /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div id="custom_card" class="card ">
    
        <div class="grid p-fluid">
          <div class="col-12 md:col-12">
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <InputText format="text" v-model="search_ticket" @change="search(1)">
                  </InputText>
                  <label> {{ $t('search_ticket') }}</label>
                </span>
              </div>
              <div class="field col-12 md:col-4">
                <span class="p-float-label">
                  <Dropdown id="Category" v-model="ticket_category_selected" :options="ticket_category_dropdown"
                     optionLabel="name" filter v-on:change="search(1)"></Dropdown>
                  <label for="Category"> {{ $t('dept') }}</label>
                </span>
              </div>
              <!-- <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <Dropdown id="Source" v-model="ticket_source_selected" :options="ticket_source_dropdown"
                    :value="ticket_source_dropdown" optionLabel="name" filter v-on:change="search(1)"></Dropdown>
                  <label for="Source">{{$t('Source')}}</label>
                </span>
              </div> -->
              <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <Dropdown id="status" v-model="ticket_status_selected" :options="ticket_status_dropdown"
                    :value="ticket_status_dropdown" optionLabel="name" filter v-on:change="search(1)"></Dropdown>
                  <label for="status">{{ $t('status') }}</label>
                </span>
              </div>
              
              <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <Dropdown id="status" v-model="status_group_selected" :options="status_group_dropdown"
                    :value="status_group_dropdown" optionLabel="name" filter v-on:change="search(1)"></Dropdown>
                  <label for="status"> {{ $t('Status_Group') }}</label>
                </span>
              </div>
              <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <Dropdown id="ward_number" v-model="ward_no" :options="ward_number_dropdown"
                     optionLabel="name" filter v-on:change="search(1)" />
                  <label for="ward_number"> {{ $t('ward_no') }}</label>
                </span>
              </div>
              
              <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <Dropdown id="priority_id" v-model="priority_dropdown" :options="ticket_priority_dropdown"
                    :value="ticket_priority_dropdown" optionLabel="name" filter v-on:change="search(1)" />
                  <label for="priority_id">{{ $t('Priority') }}</label>
                </span>
              </div>
              <div class="field col-12 md:col-2" v-if="user_type == 'Super Admin'">
                <span class="p-float-label">
                  <Dropdown id="client_dropdown" v-model="client_selected" :options="client_dropdown"
                    :value="client_dropdown" optionLabel="name" filter v-on:change="search(1)"></Dropdown>
                  <label for="Client">{{ $t('Client') }}</label>
                </span>
              </div>
              <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <Calendar id="from_date" v-model="from_date" dateFormat="dd-mm-yy" :showIcon="true" :maxDate="to_date"
                    v-on:change="search(1)" />
                  <label for="from_date"> {{ $t('from_date') }}</label>
                </span>
              </div>
              <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <Calendar id="to_date" v-model="to_date" dateFormat="dd-mm-yy" :showIcon="true" :minDate="from_date"
                    @change="search(1)" />
                  <label for="to_date">{{ $t('to_date') }}</label>
                </span>
              </div>
              <div class="field col-12 md:col-2">
                <Button icon="pi pi-refresh"  v-tooltip.bottom="$t('refresh')" @click="search(2)" class="p-button-danger"
                  style=" box-shadow: 0 2px 6px #fd9b96;
                  border-color: #fc544b !important;
                  background-color: #fc544b !important;" />
                  &nbsp;&nbsp;
                  <downloadexcel
                        
                        :header="$t('list_of_ticket')"
                        :name="$t('ticket_list')" 
                        :fetch="fetchData" 
                        :fields="jsonFields"
                        :style="{ display: 'inline-block' }"
                      >
                        <Button 
                          icon="pi pi-file-excel"  
                          class="btn_green"
                          v-tooltip.bottom="$t('excel')"
                        />
                      
                </downloadexcel>
              </div>
               
            </div>
          </div>
        </div>
        <DataTable :loading="loading" ref="dt" :lazy="true" :totalRecords="totalRecords" :paginator="true"
          :value="products" v-model:selection="selectedProducts" :dataKey="columns[0]" :rows="limit" :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="PerPageOptions"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" responsiveLayout="scroll"
          style="font-size: 12px" :rowHover="true" showGridlines>
          <template #empty>
            <b style="text-align: center">{{ $t('no_rec') }}.</b>
          </template>
          <template #header>
            <div style="text-align: right; font-weight: bold">
              <span>{{ $t('total_ticket') }}:</span> {{totalRecords }}
            </div>
           
          </template>
          <Column style="min-width: 5rem">
            <template #body="{ data }">
              <div class="grid">
               
                <div class="col-12 md:col-8 text-left md:text-left">
                  <div style="margin-top: 5px; cursor: pointer" @click="editProduct(data)">
                    <span style=" font-size:1.2rem;">
                      {{ data.ticket_no }}
                    </span>&nbsp;
                    <span style="font-size: 0.875rem;">
                      <i class="pi pi-calendar" style="color: green;" />&nbsp;
                      <span>{{ this.D_M_Y_formatDate(data.date) }}</span>
                    </span>
                    <br>
                   <!-- font-weight: 700; -->
                    <span style=" font-size:1.2rem;font-weight: 700;">  
                      {{ data.title }}
                    </span> &nbsp;&nbsp;
                    
                  </div>
                  <div style="margin-top: 5px; cursor: pointer" @click="editProduct(data)">
                    <span style=" font-size: 0.875rem;">
                      {{ data.ward_no }} / {{ data.ticket_category_name }}</span>
                  </div>
                  <div @click="editProduct(data)">
                    <span style=" font-size:0.875rem;">
                      <bold>{{ $t('complainant') }}:</bold> {{ data.customer_name }}
                    </span>
                    <span v-if="data.customer_mobile">&nbsp;( {{ data.customer_mobile }} )</span>
                    
                  </div>
                </div>
                <div class="col-12 md:col-4">
                  <div class="grid text-rigth md:text-right">
                    <div class="col-12 md:col-12">
                   
                      <Tag class="mr-2" :style="'background-color:' + data['ticket_status']['colorcode']"
                        :rounded="true">
                        {{ data['ticket_status']['name'] }}</Tag>
                      <Tag class="mr-4"
                        :style="'background-color:' + getPriorityColor(data['priority_name'] || 'No Priority')"
                        :rounded="true">
                        {{ data['priority_name'] || 'No Priority' }}
                      </Tag>
                      <Button icon="pi pi-pencil" class="p-button-rounded zoom" size="small" @click="toggle1(data)"
                        style="width:30px;height:30px; background-color: green"   v-tooltip.bottom="$t('edit_priority')"  />
                      <OverlayPanel ref="op1">
                        <div class="flex flex-column gap-3 w-25rem">
                          <h5> {{ $t('add_priority') }}</h5>
                          <div class="col-12">
                            <div class="p-fluid formgrid grid">
                              <div class="field col-12 md:col-12">
                                <span class="p-float-label">
                                  <Dropdown id="priority_id" v-model="priority_selected"
                                    :options="ticket_priority_dropdown" :value="ticket_priority_dropdown"
                                    optionLabel="name" filter size="small"></Dropdown>
                                  <label for="name">{{ $t('Priority') }}</label>
                                </span>
                              </div>
                              <div class="field col-12 md:col-4" style="margin-left: 115px;">
                                <Button  :label="$t('Save')" class="p-button-primary" @click="update_priority()" />
                              </div>
                              <div class="field col-12 md:col-4">
                                <Button  :label="$t('Cancel')" class="p-button-danger" @click="cancelPriority" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </OverlayPanel>
                    </div>
                    <!-- {{ data.agent_details[0]?.agent_name }} -->
                    <div class="col-12 md:col-12" v-if="data.agent_details.length > 0">
                      <span style="font-size: 0.875rem;">{{ $t('ass_to') }}: {{ data.agent_details[0]?.agent_name }}</span><span v-if="data.agent_details[0]?.agent_mobile">&nbsp; ( {{ data.agent_details[0]?.agent_mobile }} )</span>
                      <!-- <span style="font-size: 0.875rem;">Mobile no: {{ data.agent_details[0]?.agent_mobile }}</span> -->
                    </div>
                    <div class="col-12 md:col-12" v-if="data.agent_details.length == 0 && user_type == 'Admin'">
                      <Button icon="pi pi-plus" class="p-button zoom" size="small" @click="toggle(data)"
                        style="width:100px;height:30px;margin-right: 40px;"> {{ $t('ass_user') }}</Button>
                      <OverlayPanel ref="op">
                        <div class="flex flex-column gap-3 w-25rem">
                          <h5>  {{ $t('add_emp') }}</h5>
                          <div class="col-12">
                            <div class="p-fluid formgrid grid">
                              <div class="field col-12 md:col-12">
                                <span class="p-float-label">
                                 

                                            <Dropdown 
                                                v-model="agent_selected" 
                                                :options="agent_dropdownItems" 
                                                optionLabel="name" 
                                                :value="agent_dropdownItems._id" 
                                             
                                                :placeholder="$t('select_emp')">
                                            </Dropdown>
                                  <label for="name"> {{ $t('name') }}</label>
                                </span>
                              </div>
                              <div class="field col-12 md:col-4" style="margin-left: 115px;">
                                <Button  :label="$t('Save')" @click="assign_agent" class="p-button-primary btn_light_blue" />
                              </div>
                              <div class="field col-12 md:col-4">
                                <Button   :label="$t('Cancel')" @click="Cancel_Agent" class="p-button-danger btn_red" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </OverlayPanel>
                    </div>
                    <div class="col-12 md:col-12">
                      <OverlayPanel ref="del">
                        <div class="flex flex-column gap-3 w-25rem">
                          <h5> {{ $t('delete_ticket') }}</h5>
                          <div class="col-12">
                            <div class="p-fluid formgrid grid">
                              <div class="field col-12 md:col-12">
                                <span class="p-float-label">
                                  <Textarea format="text" v-model="delete_remark">
                                      </Textarea>
                                  <label>{{ $t('remark') }}</label>
                                </span>
                              </div>
                              <div class="field col-12 md:col-4">
                                <Button  :label="$t('delete')" @click="delete_ticket" class="p-button-primary btn_light_blue" />
                              </div>
                              <div class="field col-12 md:col-4">
                                <Button  :label="$t('Cancel')" @click="Cancel_delete" class="p-button-danger btn_red" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </OverlayPanel>
                      <!-- ----------------- -->
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Column>
        </DataTable>
        <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="product">{{ $t('delete_msg') }}</span>
          </div>
          <template #footer>
            <Button  :label="$t('no')" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
            <Button  :label="$t('yes')" icon="pi pi-check" class="p-button-text" @click="deleteuser" />
          </template>
        </Dialog>
        <Dialog v-model:visible="edit_agent_model" :breakpoints="{ '960px': '75vw' }" :style="{ width: '60vw' }"
          header="Add Employee" :modal="true" position="top">
          <div class="col-12">
            <div class="p-fluid formgrid grid" style="margin-top: 2%;">
              <div class="field col-12 md:col-12" style="margin-top: 1%;">
                <span class="p-float-label">
                  <MultiSelect v-model="agent_selected" :options="agent_dropdownItems" :value="agent_dropdownItems._id"
                    optionLabel="name" filter display="chip" :maxSelectedLabels="10">
                  </MultiSelect>
                  <label for="name">{{ $t('name') }}</label>
                </span>
              </div>
            </div>
          </div>
          <template #footer>
            <Button :label="$t('Save')" @click="assign_agent" icon="pi pi-check" class="p-button-success" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
import ColorService from "../service/ColorService";
import apis from "@/apis";
import axios from "axios";
import BreadcrumbService from "../service/BreadcrumbService";
import JsonExcel from "vue-json-excel";
import downloadexcel from "vue-json-excel3";
export default {
  data() {
    return {
      name_flag:"My",
      //search
      from_date: "",
      PerPageOptions:[10, 50, 100],
      to_date: "",
      ward_no: "",
      temp_ticket_id: "",
      delete_remark: "",
      ColorService: null,
      color_data: [],
      agent_row_data: [],
      rowdata: [],
      priority_selected: [],
      edit_agent_model: false,
      search_ticket: "",
      priority_dropdown: "",
      category_id: "",
      agent_dropdownItems: [],
      ward_number_dropdown: [],
      agent_selected: "",
      ticket_category_dropdown: [],
      ticket_category_selected: "",
      ticket_priority: "",
      status_id: "",
      ticket_status_dropdown: [],
      status_group_dropdown:[],
      status_group_selected :"",
      ticket_priority_dropdown: [],
      ticket_status_selected: "",
      user_type: "",
      ticket_source_dropdown: [],
      ticket_source_selected: "",
      source_id: "",
      client_dropdown: [],
      client_selected: "",
      clientid: "",
      page_no: 1,
      isLoadingModel: false,
      loading: false,
      fullPage: true,
      products: [],
      mis_status_group : null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      limit: 10,
      columns: [],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      BreadcrumbService: null,
      breadcrumbHome: {},
      breadcrumbItems: [],
      selected_data: '',
      jsonFields:{
        "Sr No": "sr_no",
          "Ticket No": "ticket_no",
          "Title": "title",
          "Ward No": "ward_no",
          "Department Name": "ticket_category_name",
          "Employee name": "agent_name",
          "Employee Mobile": "agent_mobile",
          "Priority Name" : "priority_name",
          
        },
    };
  },
  productService: null,
  async created() {
   
    if (this.$i18n.locale == 'en') {
        this.jsonFields={
          "Sr No": "sr_no",
          "Ticket No": "ticket_no",
          "Title": "title",
          "Ward No": "ward_no",
          "Department Name": "ticket_category_name",
          "Employee name": "agent_name",
          "Employee Mobile": "agent_mobile",
          "Priority Name" : "priority_name",
        };
      } else {
        this.jsonFields={
          "अनु. क्र":"sr_no",
          "तक्रार क्र": "ticket_no",
          "शीर्षक": "title",
          "प्रभाग क्र": "ward_no",
          "विभागाचे नाव": "ticket_category_name",
          "कर्मचारी नाव": "agent_name",
          "कर्मचारी मोबाईल": "agent_mobile",
          "प्राधान्य नाव" : "priority_name",
        }
      }
    this.productService = new ProductService();
    this.initFilters();
    this.ColorService = new ColorService();
    this.BreadcrumbService = new BreadcrumbService();
    let Breadcrum_data = this.BreadcrumbService.getBreadcrum(
      "Ticket",
      "/tickets"
    );
    this.breadcrumbHome = Breadcrum_data.home;
    this.breadcrumbItems = Breadcrum_data.items;

    if(localStorage.getItem('categoryDetails'))
      {
        var categoryDetails=await JSON.parse(localStorage.getItem('categoryDetails'));
       // console.log("department",categoryDetails);
        this.name_flag = categoryDetails.name;
        this.ticket_category_selected={};
        this.ticket_category_selected.name=categoryDetails.name;
        this.ticket_category_selected._id=categoryDetails._id;
        localStorage.setItem('categoryDetails','');
      }

      if(localStorage.getItem('wardDetails'))
      {
        var wardDetails=await JSON.parse(localStorage.getItem('wardDetails'));
        console.log("wardData3",wardDetails);
         this.name_flag = wardDetails.name;
         this.ward_no={};
         this.ward_no.name=wardDetails.name;
         this.ward_no.ward_no=wardDetails.ward_no;
        
        localStorage.setItem('wardDetails','');
      }
      // if(localStorage.getItem('category'))
      // {
      //   this.category_id = localStorage.getItem('category');
      //   console.log("category",this.category_id);
      //   // this.name_flag = category.name;
      //    this.ticket_category_selected={};
      //   // this.ticket_category_selected.name=categoryDetails.name;
      //    this.ticket_category_selected._id = this.category_id;

      //    for (let i = 0; i < this.status_group_dropdown.length; i++) {
      //     if (this.status_group_dropdown[i]['code'] == this.mis_status_group) {
      //       this.status_group_selected = this.status_group_dropdown[i];
      //       console.log("statusgroupsele",this.status_group_selected);
      //     }
      //   }


      //    localStorage.setItem('category','');
      // }
     
    await this.getPriorityList();
    await this.getTicketStatusMaster();
    await this.getTicketCategoryMaster(this.$i18n.locale);
    await this.getAgentMaster();
    await this.getTicketSourceMaster();
    await this.getClientMaster();
    await this.getWardList(this.$i18n.locale);
    await this.getTicketStatusGroup();
    await this.get_list();
    await this.get_count();
  },
  async mounted() {
    this.ColorService.getColors().then((colors) => {
      this.color_data = colors;
    });
    //console.log("lang",this.currentLocale);
    this.id = this.$route.params.id;
    // this.mis_status_group = parseInt(localStorage.getItem("mis_status_group"), 10);
    // console.log("mis_status",this.mis_status_group);
    this.user_type = localStorage.getItem("user_type");
    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    // if (this.totalRecords == 0) {
    //   await this.get_count();
    // }
    //await this.get_list();
    //alert(this.id);
  },
  watch: {
    currentLocale(){
      this.getTicketCategoryMaster(this.currentLocale);
      this.getTicketStatusMaster(this.currentLocale);
       this.getWardList(this.currentLocale);
      this.get_list(this.currentLocale);
      this.get_count()
     
      if (this.currentLocale == 'en') {
        this.jsonFields={
          "Sr No": "sr_no",
          "Ticket No": "ticket_no",
          "Title": "title",
          "Ward No": "ward_no",
          "Department Name": "ticket_category_name",
          "Employee name": "agent_name",
          "Employee Mobile": "agent_mobile",
          "Priority Name" : "priority_name",
        };
      } else {
        this.jsonFields={
          "अनु. क्र":"sr_no",
          "तक्रार क्र": "ticket_no",
          "शीर्षक": "title",
          "प्रभाग क्र": "ward_no",
          "विभागाचे नाव": "ticket_category_name",
          "कर्मचारी नाव": "agent_name",
          "कर्मचारी मोबाईल": "agent_mobile",
          "प्राधान्य नाव" : "priority_name",
        }
      }
      
      
    },
    async from_date() {
    if (this.from_date && !this.to_date) {
      this.search(1);
    } else if (this.from_date && this.to_date) {
      this.search(1);
    }
  },
  async to_date() {
    if (this.from_date && this.to_date) {
      this.search(1);
    }
  },
  async search_ticket() {
    if (this.search_ticket) {
      this.search(1);
    }
  },

    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  computed: {
    currentLocale() {
      
      return this.$i18n.locale;
    },
   

  },
  methods: {
    fetchData() {
      var temp_data = [];
      for (let i = 0; i < this.products.length; i++) {
        var item = this.products[i];
        var sr_no = i + 1 + this.limit * (this.page_no - 1);
        temp_data.push({
          sr_no: sr_no,
          ticket_no : item.ticket_no          ,
          title : item.title          ,
          ward_no : item.ward_no,
          ticket_category_name : item.ticket_category_name,
          customer_name : item.customer_name,
          agent_name: item.agent_details?.[0]?.agent_name || "",  
          agent_mobile: item.agent_details?.[0]?.agent_mobile || "", 
          priority_name : item.priority_name,
        });
      }
      return temp_data;
    },

    

    getPriorityColor(priorityName) {
      const priorityColors = {
        High: '#FF4219',
        Medium: '#00FFC3',
        Low: '#C300FF',
        //  'No Priority':'#C70039',
        Critical: '#C70039',
      };


      return priorityColors[priorityName] || '#CCCCCC';
    },
    toggle1(data) {
      this.priority_selected = [];
      this.rowdata = [];
      this.priority_selected = data.priority_id;
      this.rowdata = data;
      this.$refs.op1.toggle(event);
    },
    cancelPriority() {
      // this.data['priority_id'] = null; 
      this.$refs.op1.hide();
    },
    async update_priority() {
      let details = {
        "priority_id": this.priority_selected._id,
        "_id": this.rowdata._id,
         lang:this.$i18n.locale
      }
      var promise = apis.updatepriority(details);
      promise.then((response) => {
        this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
        this.get_list();
        this.get_count();
      });
    },
    getBackgroundColor(data) {
      if (data.name === 'Medium') {
        return '#ffc107 !important';
      }
      if (data.name === '') {
        return '#ffc107 !important';
      }
      else if (data.name === 'Low') {
        return '#00B064 !important';
      } else if (data.name === 'High') {
        return '#fc544b !important';
      }
    },
    bordercolor(data) {
      if (data.name === 'Medium') {
        return '#ffc107 !important';
      } else if (data.name === 'Low') {
        return '#00B064 !important';
      } else if (data.name === 'High') {
        return '#fc544b !important';
      }
    },
    boxshadow(data) {
      if (data.name === 'Medium') {
        return '0 2px 6px #ffc107';
      } else if (data.name === 'Low') {
        return '0 2px 6px #00B064';
      } else if (data.name === 'High') {
        return '0 2px 6px #fd9b96';
      }
    },
    getcolorcode(name) {
      var name = this.convert_name(name)
      var temp = name.substring(0, 1);
      for (let index = 0; index < this.color_data.length; index++) {
        if (this.color_data[index].name == temp) {
          return this.color_data[index].code;
        }
      }
    },
    convert_name(name) {
      var x = this.ColorService.convertToSN(name);
      return x.toString();
    },
    Cancel_Agent() {
      this.agent_selected = [];
      this.agent_row_data = [];
      this.$refs.op.hide();
    },
    toggle(data) {
      this.agent_selected = [];
      this.agent_row_data = [];
      var temp = [];
      for (let index = 0; index < data.agent_details.length; index++) {
        temp.push({ _id: data.agent_details[index].agent_id, name: data.agent_details[index].agent_name });
      }
      this.agent_selected = temp;
      this.agent_row_data = data;
      this.$refs.op.toggle(event);
    },
    toggle_priority(data) {
      this.data['priority_id'] = [];
      var temp = [];
      for (let index = 0; index < data.priority_details.length; index++) {
        temp.push({ _id: data.priority_details[index].agent_id, name: data.priority_details[index].agent_name });
      }
      this.data['priority_id'] = temp;
      this.agent_row_data = data;
      this.$refs.op.toggle(event);
    },
    async edit_agent(data) {
      var temp = [];
      for (let index = 0; index < data.agent_details.length; index++) {
        temp.push({ _id: data.agent_details[index].agent_id, name: data.agent_details[index].agent_name });
      }
      this.agent_selected = temp;
      this.agent_row_data = data;
      this.edit_agent_model = true;
    },
    async show_Agent(info, index) {
      return false;
      await localStorage.setItem('AgentDetails', '');
      await localStorage.setItem('AgentDetails', JSON.stringify(info));
      //data not present
      this.$router.push({ name: "agentdetails" });
    },
    D_M_Y_formatDate(date_parm) {
      if (date_parm) {
        let date = new Date(date_parm);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        let new_date = dd + "/" + mm + "/" + yyyy;
        // alert(dd)
        if (dd.toString() != "NaN") {
          //console.log(new_date);
          return new_date;
        } else {
          return date_parm;
        }
      }
    },
    async assign_agent() {
      var agent = [];
      for (let index = 0; index < this.agent_selected.length; index++) {
        agent.push(this.agent_selected[index]._id);
      }
      let details = {
        "ticket_no": this.agent_row_data.ticket_no,
        "client_id": "65a4f82f6177e69880ece5d6",
        "agent_id": [this.agent_selected._id],
        "user_id": localStorage.getItem("id"),
        lang:this.$i18n.locale
      }
      var promise = apis.assignAgent(details);
      promise.then((response) => {
        this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
        this.edit_agent_model = false;
        this.$refs.op.hide();
        this.get_list();
        this.get_count();
      });
    },
    async update_status(ticket_no, status) {
      if (!ticket_no || !status) {
        this.$swal("Error !!!");
      }
      let details = {
        "user_id": localStorage.getItem("id"),
        "ticket_no": ticket_no,
        "client_id": localStorage.getItem("client_id"),
        "status": status._id,
        lang:this.$i18n.locale
      }
      var promise = apis.updateTicketStatus(details);
      promise.then((response) => {
        this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
        this.get_list();
        this.get_count();
      });
    },
    async search(count) {
      if (count == 2) {
        this.search_ticket = "";
        this.category_id = "";
        this.from_date = "";
        this.to_date = "";
        this.ward_no = "";
        this.priority_dropdown = "";
        this.ticket_category_selected = "";
        this.ticket_source_selected = "";
        this.status_id = "";
        this.ticket_status_selected = "";
        this.status_group_selected = "";
        this.client_selected = "";
        this.clientid = "";
        this.mis_status_group = ""
      }
      await this.get_list();
      await this.get_count();
    },
    async viewProduct(product) { },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    async getTicketCategoryMaster() {
      var data = {
        client_id: "65a4f82f6177e69880ece5d6",
        lang:this.$i18n.locale,
        user_id:localStorage.getItem("id")
      
      };
      // this.isLoadingModel = true;
      var promise = apis.TicketCategoryMaster(data);
      promise.then((response) => {
        // this.isLoadingModel = false;
        this.ticket_category_dropdown = [];
        for (let index = 0; index < response.data.data.length; index++) {
          this.ticket_category_dropdown.push({name:response.data.data[index]['name'],_id:response.data.data[index]['_id']})
          
        }

        if(localStorage.getItem('category'))
      {
        this.category_id = localStorage.getItem('category');
        console.log("category",this.category_id);
        // this.name_flag = category.name;
         this.ticket_category_selected={};
        // this.ticket_category_selected.name=categoryDetails.name;
        // this.ticket_category_selected._id = this.category_id;

         for (let i = 0; i < this.ticket_category_dropdown.length; i++) {
          if (this.ticket_category_dropdown[i]['_id'] == this.category_id) {
            this.ticket_category_selected = this.ticket_category_dropdown[i];
           // console.log("statusgroupsele",this.ticket_category_selected);
          }
        }


         localStorage.setItem('category','');
      }
      });
    },
    async getWardList() {
      var data = {
        //client_id: "65a4f82f6177e69880ece5d6",
        user_id: localStorage.getItem("id"),
        lang:this.$i18n.locale
     
      };
      // this.isLoadingModel = true;
      var promise = apis.getWardList(data);
      promise.then((response) => {
        // this.isLoadingModel = false;
        this.ward_number_dropdown = response.data.data;

       

        this.ward_number_dropdown = [];
        for (let index = 0; index < response.data.data.length; index++) {
          this.ward_number_dropdown.push({name:response.data.data[index]['name'],ward_no:response.data.data[index]['ward_no']})
          
        }
        //console.log("warddata",this.ward_number_dropdown)
        if(localStorage.getItem('wardNumber'))
      {
        this.ward_no = localStorage.getItem("wardNumber");
        //console.log("ward",this.ward_no);
        
        for (let i = 0; i < this.ward_number_dropdown.length; i++) {
          if (this.ward_number_dropdown[i]['ward_no'] == this.ward_no) {
            this.ward_no = this.ward_number_dropdown[i];
            //console.log("wardnoselected",this.ward_no);
          }
        }
        
        localStorage.setItem("wardNumber", '');
      }

        
      });
    },
    async getPriorityList() {
      var data = {
        //client_id: "65a4f82f6177e69880ece5d6",
        user_id: localStorage.getItem("id"),

      };
      // this.isLoadingModel = true;
      var promise = apis.getPriorityList(data);
      promise.then((response) => {
        // this.isLoadingModel = false;
        this.ticket_priority_dropdown = response.data.data;
      });
    },
    async getTicketSourceMaster() {
      var data = {
        client_id: "65a4f82f6177e69880ece5d6",
        user_id: localStorage.getItem("id"),
      };
      var promise = apis.getTicketSource(data);
      promise.then((response) => {
        var local_temp = response.data.data;
        var local_array = [];
        for (let index = 0; index < local_temp.length; index++) {
          local_array.push({ _id: local_temp[index].name, name: local_temp[index].name })
        }
        this.ticket_source_dropdown = local_array;
      });
    },
    async getAgentMaster() {
      var data = {
        client_id: "65a4f82f6177e69880ece5d6",
        user_id: localStorage.getItem("id"),
      };
      var promise = apis.agentListDropdown(data);
      promise.then((response) => {
        var temp = [];
        for (let index = 0; index < response.data.data.length; index++) {
          temp.push({ _id: response.data.data[index].agent_id, name: response.data.data[index].agent_name });
        }
        this.agent_dropdownItems = temp;
      });
    },
    async getTicketStatusMaster( ) {
      this.ticket_status_dropdown="";
      var data = {
        client_id: "65a4f82f6177e69880ece5d6",
        user_id: localStorage.getItem("id"),
        lang:this.$i18n.locale
        
      };
      // this.isLoadingModel = true;
      var promise = apis.TicketStatusMaster(data);
      promise.then((response) => {
        // this.isLoadingModel = false;
        this.ticket_status_dropdown = response.data.data;
        var ticket_status = localStorage.getItem("ticket_status");
        //console.log("tickt",ticket_status)
        //////////////////////////////////////////////////////////////////////////////////////
        for (let i = 0; i < this.ticket_status_dropdown.length; i++) {
          if (this.ticket_status_dropdown[i]['_id'] == ticket_status) {
            this.ticket_status_selected = this.ticket_status_dropdown[i];
          }
        }
        localStorage.setItem("ticket_status", '');
      });
      //////////////////////////////////////////////////////////////////////////
    },
    async getTicketStatusGroup() {
      // var data = {
      //   client_id: "65a4f82f6177e69880ece5d6",
      //   user_id: localStorage.getItem("id"),
      // };
      // this.isLoadingModel = true;
      var promise = apis.getAllTicketStatusGroup();
      promise.then((response) => {
        // this.isLoadingModel = false;
        this.status_group_dropdown = response.data.data;
         //console.log("statusgroup",this.status_group_dropdown);
        this.mis_status_group = localStorage.getItem("mis_status_group");
        console.log("mis",this.mis_status_group);
        
        for (let i = 0; i < this.status_group_dropdown.length; i++) {
          if (this.status_group_dropdown[i]['code'] == this.mis_status_group) {
            this.status_group_selected = this.status_group_dropdown[i];
            //console.log("statusgroupsele",this.status_group_selected);
          }
        }
        
        localStorage.setItem("mis_status_group", '');
      });
      
    },
    format_date_as_dd_mm_yyyy() {
      let date = new Date();
      let dd = date.getDate();
      let mm = date.getMonth();
      let yyyy = date.getFullYear().toString();
      let tt = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
      let new_date = dd + "-" + mm + "-" + yyyy;
      return new_date;
    },
    async getClientMaster() {
      var data = {
        limit: 10000,
        page_no: 1,
        count: false,
        user_id: localStorage.getItem("id"),
      };
      var promise = apis.getclientist(data);
      promise.then((response) => {
        this.client_dropdown = response.data.data;
      });
    },
    formatDate(date) {
      if (!date) return "";
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, "0");
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const year = d.getFullYear();
      // Return in desired format, e.g., DD/MM/YYYY
      return `${year}-${month}-${day}`;
    },
    async get_count() {
      var customer_id = "";
      if (localStorage.getItem("user_type") == "Customer") {
        customer_id = localStorage.getItem("id");
      }
      var agent_id = "";
      if (localStorage.getItem("user_type") == "Employee") {
        agent_id = localStorage.getItem("id");
      }
      // var status_id = "";
      // if (localStorage.getItem("ticketStatus") == "प्रलंबित") {
      //   status_id = localStorage.getItem("ticketStatus");
      // }
      // var status_id = "";
      // if (localStorage.getItem("ticketStatus") == "बंद") {
      //   status_id = localStorage.getItem("ticketStatus");
      // }
      //get category id
      //this.category_id = this.ticket_category_selected._id;
      //get status
      ///////////////////////////////////////////////////////////////////////
      if (this.ticket_status_selected._id) {
        this.status_id = this.ticket_status_selected._id;
      }
      else if (localStorage.getItem('ticket_status')) {
        this.status_id = localStorage.getItem('ticket_status');
      }
      else {
        this.status_id = '';
      }
      if (this.status_group_selected.code) {
        this.mis_status_group = this.status_group_selected.code;
      }
      else if (localStorage.getItem('mis_status_group')) {
        this.mis_status_group = parseInt(localStorage.getItem("mis_status_group"), 10);
      }
      else {
        this.mis_status_group = '';
      }
      //alert(this.ticket_category_selected)
      if (this.ticket_category_selected._id) {
        this.category_id = this.ticket_category_selected._id;
      }
      else if (localStorage.getItem('category')) {
        this.category_id = localStorage.getItem("category");
      }
      else {
        this.category_id = '';
      }
       var ward_no = '';
      // if (this.ward_no) {
      //   ward_no = this.ward_no.ward_no;
      // }
      if (this.ward_no.ward_no) {
        ward_no = this.ward_no.ward_no;
      }
      else if (localStorage.getItem('wardNumber')) {
        ward_no = localStorage.getItem("wardNumber");
      }
     
      var priority = '';
      if (this.priority_dropdown) {
        priority = this.priority_dropdown._id;
      }
      ////////////////////////////////////////////////
      this.source_id = this.ticket_source_selected._id;
      this.clientid = "";
      this.clientid = this.client_selected._id;
      if (this.clientid == '' || this.clientid == undefined) {
        this.clientid = localStorage.getItem("client_id");
      }
      var data = {
        user_id: localStorage.getItem("id"),
        customer_id: customer_id,
        title: "",
        category: this.category_id,
        type: "",
        status: this.status_id,
        client_id: this.clientid,
        ticket_no: this.search_ticket,
        agent_id: agent_id,
        department_id: "",
        count: true,
        source: this.source_id,
        // from_date: this.from_date, 
        //   to_date: this.to_date,
        mis_status_group :this.mis_status_group,    
        from_date: this.formatDate(this.from_date),
        to_date: this.formatDate(this.to_date),
        status_name: this.status_name,
        ward_no: ward_no,
        priority_id: priority,
        lang:this.$i18n.locale
        
      };
      
      this.loading = true;
      var promise = apis.ticketlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        this.totalRecords = response.data.data;
       // console.log("total",this.totalRecords);
        if(this.totalRecords>100)
          {
            this.PerPageOptions.push(this.totalRecords);     
          }
        //console.log("total",this.totalRecords);
      });
      //localStorage.setItem("mis_status_group", '');
      //console.log(data);
    },
    dateFormatYMD(inputDate) {
      var postdate = inputDate;
      if (inputDate) {
        inputDate = new Date(inputDate);
        if (inputDate.toString() == "NaN-NaN-NaN") {
          return postdate;
        } else {
          let date = ("0" + inputDate.getDate()).slice(-2);
          // current month
          let month = ("0" + (inputDate.getMonth() + 1)).slice(-2);
          // current year
          let year = inputDate.getFullYear();
          if (date.toString() == "aN") {
            return postdate;
          } else {
            return year + "-" + month + "-" + date;
          }
        }
      } else {
        return "";
      }
    },
    async get_list() {
      var customer_id = "";
      if (localStorage.getItem("user_type") == "Customer") {
        customer_id = localStorage.getItem("id");
      }
      var agent_id = "";
      if (localStorage.getItem("user_type") == "Employee") {
        agent_id = localStorage.getItem("id");
      }
      // var status_id = "";
      // if (localStorage.getItem("ticketStatus") == "प्रलंबित") {
      //   status_id = localStorage.getItem("ticketStatus");
      // }
      // var status_id = "";
      // if (localStorage.getItem("ticketStatus") == "बंद") {
      //   status_id = localStorage.getItem("ticketStatus");
      // }
      //get category id
      //this.category_id = this.ticket_category_selected._id;
      //get status
      ///////////////////////////////////////////////////////////////////////
      if (this.ticket_status_selected._id) {
        this.status_id = this.ticket_status_selected._id;
      }
      else if (localStorage.getItem('ticket_status')) {
        this.status_id = localStorage.getItem('ticket_status');
      }
      else {
        this.status_id = '';
      }
      if (this.status_group_selected.code) {
        this.mis_status_group = this.status_group_selected.code;
      }
      else if (localStorage.getItem('mis_status_group')) {
        this.mis_status_group = parseInt(localStorage.getItem("mis_status_group"), 10);
      }
      else {
        this.mis_status_group = '';
      }
      if (this.ticket_category_selected._id) {
        this.category_id = this.ticket_category_selected._id;
      }
      else if (localStorage.getItem('category')) {
        this.category_id = localStorage.getItem("category");
      }
      else {
        this.category_id = '';
      }
       var ward_no = '';
      // if (this.ward_no) {
      //   ward_no = this.ward_no.ward_no;
      // }
      if (this.ward_no.ward_no) {
        ward_no = this.ward_no.ward_no;
      }
      else if (localStorage.getItem('wardNumber')) {
        ward_no = localStorage.getItem("wardNumber");
      }
     
      var priority = '';
      if (this.priority_dropdown) {
        priority = this.priority_dropdown._id;
      }
      ////////////////////////////////////////////////
      this.source_id = this.ticket_source_selected._id;
      this.clientid = "";
      this.clientid = this.client_selected._id;
      if (this.clientid == '' || this.clientid == undefined) {
        this.clientid = localStorage.getItem("client_id");
      }
      var data = {
        user_id: localStorage.getItem("id"),
        customer_id: customer_id,
        title: "",
        category: this.category_id,
        type: "",
        status: this.status_id,
        client_id: this.clientid,
        ticket_no: this.search_ticket,
        agent_id: agent_id,
        department_id: "",
        page_no: this.page_no,
        limit: this.limit,
        count: false,
        source: this.source_id,
        // from_date: this.from_date, 
        //   to_date: this.to_date,
        mis_status_group :this.mis_status_group,    
        from_date: this.formatDate(this.from_date),
        to_date: this.formatDate(this.to_date),
        status_name: this.status_name,
        ward_no: ward_no,
        priority_id: priority,
        lang:this.$i18n.locale
        
      };
      this.loading = true;
      var promise = apis.ticketlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        this.products = response.data.data;
        console.log("product",this.products);
        //   for (let i = 0; i < this.products.length; i++) {
        //   for (let j = 0; j < this.products[i].agent_details.length; j++) {
        //     if (this.products[i].agent_details[j].agent_profile == "") {
        //      // console.log("blank",this.products[i].agent_details[j].agent_profile);
        //       this.products[i].agent_details[j].agent_profile = "images/eTicket_logo_new.png";
        //     }
        //   }
        // }
        // for (this.products[0] = 0; this.products[0] < this.products[0].length; this.products[0]++) {
        //   if (this.products[0].agent_details == "") {
        //     console.log(this.products[0].agent_details);
        //   } 
        // }
        // Map agent_details into products
  this.products = this.products.map(product => {
    if (product.agent_details && Array.isArray(product.agent_details)) {
      product.agent_details = product.agent_details.map(agent => ({
        ...agent,
        parent_id: product.id, // Example of adding parent product info
      }));
    }
    return product;
  });
        if (this.columns.length == 0) {
          if (this.products.length > 0) {
            this.columns = Object.keys(this.products[0]);
          }
        }
        
      });
     // localStorage.setItem("mis_status_group", '');
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
      this.$router.push({ name: "create_ticket" });
    },
    async editProduct(product) {
      this.file_attachment = "";
      this.product = { ...product };
      await localStorage.setItem('TicketDetails', '');
      await localStorage.setItem('TicketDetails', JSON.stringify(product));
      this.$router.push({ name: "ticket_details" });
      //console.log(localStorage.getItem("TicketDetails"));
    },
    confirmDeleteProduct(product) {
      this.$confirm.require({
        target: event.currentTarget,
        //message: 'Are you sure you want to Delete ticket?',
        message:this.$t('confirm_delete_customer'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          var payload =
          {
            "client_id": "65a4f82f6177e69880ece5d6",
            "user_id": localStorage.getItem("id"),
            "ticket_no": product.ticket_no,
            lang:this.$i18n.locale
          }
          this.isLoadingModel = true;
          var promise = apis.deleteTicket(payload);
          promise.then((response) => {
            this.isLoadingModel = false;
            if (response.data.status == true) {
              this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
              this.get_list();
              this.get_count();
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }
            else {
              this.$toast.add({ severity: 'error', summary: 'ERROR!!!', detail: response.data.message, life: 3000 });
            }
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: 'Cancel', detail: 'You have Cancelled', life: 3000 });
        }
      });
    },
    delete_toggle(data) {
      this.temp_ticket_id = data.ticket_no;
      this.$refs.del.toggle(event);
    },
    delete_ticket() {
      if (this.delete_remark == null || this.delete_remark == "" || this.delete_remark == undefined) {
        this.$toast.add({
          severity: "error",
          summary: "error",
         // detail: "Please Enter Remark",
          detail: this.$t("enter_remark"), 
          life: 3000,
        });
        return false;
      }
      else {
        var payload =
        {
          "client_id": "65a4f82f6177e69880ece5d6",
          "user_id": localStorage.getItem("id"),
          "ticket_no": this.temp_ticket_id,
          "remark": this.delete_remark,
          lang:this.$i18n.locale
        }
        this.isLoadingModel = true;
        var promise = apis.deleteTicket(payload);
        promise.then((response) => {
          this.isLoadingModel = false;
          if (response.data.status == true) {
            this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
            this.get_list();
            this.get_count();
            this.temp_ticket_id = "";
            this.delete_remark = "";
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
          else {
            this.$toast.add({ severity: 'error', summary: 'ERROR!!!', detail: response.data.message, life: 3000 });
          }
        });
      }
    },
    Cancel_delete() {
      this.temp_ticket_id = "";
      this.delete_remark = "";
      this.$refs.del.hide();
    },
    async exportCSV() {
      this.limit = this.totalRecords;
      this.get_list();
      await this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: { downloadExcel: JsonExcel,downloadexcel },
};
</script>
<style scoped lang="scss">
@import "../assets/demo/badges.scss";

#custom_card {
  border-top: 3px solid #6b719b;
}

.In_Progress {
  background-color: yellow;
  color: white
    /* Adjust the styling as per your requirement */
}

.Open {
  background-color: green;
  color: white
    /* Adjust the styling as per your requirement */
}

.Closed {
  background-color: red;
  color: white
    /* Adjust the styling as per your requirement */
}

.zoom {
  transition: transform .2s;
}

.zoom:hover {
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
}
</style>